<template>
  <div>
    <div class="search">
      <el-input placeholder="请输入设备名称查询" style="width: 200px; padding-right: 10px;" v-model="name"></el-input>
      <el-input placeholder="请输入制造商查询" style="width: 200px; padding-right: 10px;" v-model="number"></el-input>

      <el-button type="info" plain style="margin-left: 10px" @click="load(1)">查询</el-button>
      <el-button type="warning" plain style="margin-left: 10px" @click="reset">重置</el-button>
    </div>

    <div class="operation">
      <el-button type="primary" plain @click="handleAdd">新增设备</el-button>
    </div>

    <div class="table">
      <el-table :data="tableData" strip @selection-change="handleSelectionChange" stripe border :header-cell-class-name="'headerBg'" >
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column prop="id" label="序号" width="50" align="center"></el-table-column>
        <el-table-column prop="name" label="设备名称" width="180" align="center"></el-table-column>
        <el-table-column prop="shortName" label="制造商" width="90" align="center"></el-table-column>
        <el-table-column prop="number" label="设备描述" width="80" align="center"></el-table-column>
        <el-table-column prop="address" label="相关下载" width="230" align="center"></el-table-column>
        <el-table-column label="装备图片" align="center">
          <template v-slot="scope">
            <div v-if="scope.row.imgPath" style="display: flex; align-items: center">
              <template v-for="src in scope.row.imgPath">
                <el-image style="height: 30px; border-radius: 1%;padding-right: 10px;" fit="contain"
                          :src="src" :preview-src-list="scope.row.imgPath"></el-image>
              </template>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="180">
          <template v-slot="scope">
            <el-button size="mini" type="primary" plain @click="handleEdit(scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" plain @click="del(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="pageNum" :page-sizes="[5, 10, 20]" :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>

    <el-dialog :title="title" :visible.sync="fromVisible" width="200" :close-on-click-modal="false" destroy-on-close>
      <el-form :model="form" label-width="100px" style="padding-right: 50px" :rules="rules" ref="formRef">
        <el-row>
          <el-col :span="12">
            <el-form-item label="设备名称">
              <el-input v-model="form.number" :disabled="title!=='新增'"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="制造商">
              <el-input v-model="form.name" :disabled="title!=='新增'"></el-input>
            </el-form-item>
          </el-col>

        </el-row>
        <el-row>
          <el-form-item label="描述">
            <el-input v-model="form.shortName"></el-input>
          </el-form-item>
        </el-row>
        <el-form-item label="相关下载">
          <el-input v-model="form.address" :disabled="title!=='新增' && title!=='编辑'"></el-input>
        </el-form-item>

        <el-form-item label="设备图片">
          <el-upload
              class="avatar-uploader"
              :action="$baseUrl + '/stationinfo/uploadphoto'"
              :headers="{ token: user.token }"
              :show-file-list="false"
              :data="{ department:user.department, name: form.name, id:0 }"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload">
            <img v-if="imgPath[0]" :src="imgPath[0]" class="avatar" style="width: 100px; height: 100px" >
            <div v-else class="el-icon-plus avatar-uploader-icon">请上传图片</div>
            <el-button type="primary" size="mini">上传</el-button>
          </el-upload>

          <div v-if="form.id" style="width:100%;display: flex;flex-wrap: wrap;">
            <div  class="imgBox" style="padding: 10px; margin: 10px; background-color: #cccccc;border-radius: 5px">
              <el-imqge :src="imgPath[0]" style="width: 100px; height: 100px" alt="请上传设备图片" fit="contain"/>
              <div style="display: flex;justify-content: space-between">
                <el-upload :show-file-list="false"
                           class="avatar-uploader"
                           :action="$baseUrl + '/stationinfo/uploadphoto'"
                           :headers="{ token: user.token }"
                           :data="{ department:user.department, name: form.name, id:0 }"
                           list-type="picture"
                           :on-success="handleImgSuccess"
                >
                  <el-button type="primary" size="mini">上传</el-button>
                </el-upload>
              </div>
            </div>

            <div  class="imgBox" style="padding: 10px; margin: 10px; background-color: #cccccc;border-radius: 5px">
              <img :src="imgPath[1]" style="width: 100px; height: 100px" alt="请上传方位图" fit="contain">
              <div style="display: flex;justify-content: space-between">
                <el-upload :show-file-list="false"
                           class="avatar-uploader"
                           :action="$baseUrl + '/stationinfo/uploadphoto'"
                           :headers="{ token: user.token }"
                           :data="{ department:user.department, name: form.name, id:1 }"
                           list-type="picture"
                           :on-success="handleImgSuccess"
                >
                  <el-button type="primary" size="mini">上传</el-button>
                </el-upload>
              </div>
            </div>

            <div class="imgBox" style="padding: 10px; margin: 10px; background-color: #cccccc;border-radius: 5px">
              <img :src="imgPath[2]" style="width: 100px; height: 100px" alt="请上传方位图" fit="contain">
              <div style="display: flex;justify-content: space-between">
                <el-upload :show-file-list="false"
                           class="avatar-uploader"
                           :action="$baseUrl + '/stationinfo/uploadphoto'"
                           :headers="{ token: user.token }"
                           :data="{ department:user.department, name: form.name, id:2 }"
                           list-type="picture"
                           :on-success="handleImgSuccess"
                >
                  <el-button type="primary" size="mini">上传</el-button>
                </el-upload>
              </div>
            </div>

          </div>
          <div v-else>
            靓照新增好站点在添加!
          </div>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="fromVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: "Stations",
  data(){
    return{
      tableData: [],  // 所有的数据
      pageNum: 1,   // 当前的页码
      pageSize: 10,  // 每页显示的个数
      total: 0,
      name: '' ,
      number: '',
      fromVisible: false,
      title: '编辑',  // 编辑 新增
      form: {},
      fileList:[],
      user: JSON.parse(localStorage.getItem('user') || '{}'),
      imgPath: [],  // 9个方位图，0 1 2 3 ... 8
    }
  },
  created() {
    this.load(1)
  },
  methods: {
    handleAdd() {   // 新增站点
      this.title = '新增'
      this.fileList =[]
      this.form = {}  // 新增数据的时候清空数据
      this.fromVisible = true   // 打开弹窗
    },
    handleEdit(row) {   // 编辑数据
      this.title = '编辑'
      this.form = JSON.parse(JSON.stringify(row))  // 给form对象赋值  注意要深拷贝数据
      this.fileList = []
      this.imgPath = new Array(9).fill('');
      if(this.form.imgPath){
        this.form.imgPath.map((item)=>{ // 站名_?.jpg 如https://www.yz-awen.cn:9090/file/stations/宜州庆远气象观测站_1.jpg
          let fileName=item.substring(item.lastIndexOf('/')+1, item.lastIndexOf('.'));
          let number = fileName.substring(fileName.length-1,fileName.length)
          if(fileName.substring(0,fileName.length-1)===(this.form.name+'_')){
            this.imgPath[number] = item
          }else{
            this.imgPath[number] = ''
          }
          this.fileList.push({name:'test', url: item})
        })
      }
      this.fromVisible = true   // 打开弹窗
    },
    handleImgSuccess(response, file, fileList) {
      // 把方位图片的链接更新到本地
      let url = response.data  // url
      let fileName=url.substring(url.lastIndexOf('/')+1, url.lastIndexOf('.')); // 取得文件名（不含后缀）
      let number = fileName.substring(fileName.length-1,fileName.length)        // 取得方位序号
      this.imgPath[number] = ''
      this.imgPath[number] = url    // 填入相应方位
      console.log("上传后imgPath:", this.imgPath)
    },
    handleRemove(file, fileList){
      console.log(file.name, file.url, file.uid, fileList);
      this.fileList = fileList;
    },
    save() {   // 保存按钮触发的逻辑  它会触发新增或者更新
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          this.form.imgPath = ''
          var arr = []
          this.imgPath.map((item)=>{
            if(item) arr.push(item)
          })
          this.form.imgPath = arr.join(',')
          console.log(this.form)
          this.request.post('/stationinfo',  this.form).then(res => {
            if (res.code === '200') {  // 表示成功保存
              this.$message.success('保存成功')
              this.load(1)
              this.fromVisible = false
            } else {
              this.$message.error(res.msg)  // 弹出错误的信息
            }
          })
        }
      })
    },
    del(id) {   // 单个删除
      this.$confirm('您确定删除吗？', '确认删除', {type: "warning"}).then(response => {
        this.request.delete('/stationinfo/' + id).then(res => {
          if (res.code === '200') {   // 表示操作成功
            this.$message.success('操作成功')
            this.load(1)
          } else {
            this.$message.error(res.msg)  // 弹出错误的信息
          }
        })
      }).catch(() => {
      })
    },
    handleSelectionChange(rows) {   // 当前选中的所有的行数据
      this.ids = rows.map(v => v.id)
    },
    delBatch() {   // 批量删除
      if (!this.ids.length) {
        this.$message.warning('请选择数据')
        return
      }
      this.$confirm('您确定批量删除这些数据吗？', '确认删除', {type: "warning"}).then(response => {
        this.request.delete('/stationinfo/delete/batch', {data: this.ids}).then(res => {
          if (res.code === '200') {   // 表示操作成功
            this.$message.success('操作成功')
            this.load(1)
          } else {
            this.$message.error(res.msg)  // 弹出错误的信息
          }
        })
      }).catch(() => {
      })
    },
    load(pageNum) {  // 分页查询
      if (pageNum) this.pageNum = pageNum
      this.request.get('/stationinfo/page', {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          name: this.name,      // 站名
          number: this.number,      // 站号
        }
      }).then(res => {
        console.log(res.data)
        var newDta = res.data.records.map((item)=>{ // 把 'test1,test2,...' 字串转换为 ['test1','test2',...]数组
          if(item.imgPath)
            item.imgPath=item.imgPath.split(',')
        })
        this.tableData = res.data?.records
        this.total = res.data?.total
      })
    },
    reset() {
      this.name = this.number =  null
      this.load(1)
    },
    handleCurrentChange(pageNum) {
      this.load(pageNum)
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize=val
      this.load(1)
    },

    handleAvatarSuccess(response, file, fileList) {
      // 把头像属性换成上传的图片的链接
      this.imgPath[0] = response.data
      this.fileList.push({name: 'test', url:response.data})
    },
    handleCirculation(){//流转按钮
      this.circulationVisible = true
    },
    circulationSave(){
      this.circulationVisible = false
    },
  },
}
</script>

<style scoped>
.operation{
  padding: 10px;
}
</style>le>
